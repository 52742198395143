<template>
  <div class="form-narrow">
    <div class="form-section-title">contact information</div>
    <div class="form-row">
      <div class="form-col">
        <label class="label">first name</label>
        <div>{{preparedInitialValues.firstName}}</div>
      </div>
      <div class="form-col">
        <label class="label">last name</label>
        <div>{{preparedInitialValues.lastName}}</div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <label class="label">email</label>
        <div>{{preparedInitialValues.email}}</div>
      </div>
      <div class="form-col">
        <label class="label">phone</label>
        <div>{{preparedInitialValues.phone}}</div>
      </div>
    </div>

    <div class="form-section-title items-center mt-10">cognito identity</div>
    <div v-if="identities.length === 0" class="text-sm mt-4">no cognito identity linked</div>
    <div v-for="identity of identities" :key="identity.id" class="mb-6">
      <div class="form-row">
        <div class="form-col">
          <label class="label">email</label>
          <div class="mb-2">{{identity.email}}</div>
          <label class="label">phone</label>
          <div>{{identity.phone || '-'}}</div>
        </div>
        <div class="form-col">
          <label class="label">identity id</label>
          <div class="mb-2">{{identity.id}}</div>
          <label class="label">cognito identity id</label>
          <div>{{identity.cognitoIdentityId}}</div>
        </div>
      </div>
    </div>

    <ModalFooter :footer="footer" :tertiary="cancel" :primary="cancel" />
  </div>
</template>

<script>
  import {get} from 'lodash-es';
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ModalFooter from "@/components/ui/modals/ModalFooter";

  export default {
    name: "PersonDetails",
    components: {
      ModalFooter,
    },
    mixins: [
      ModalNavigation,
    ],
    props: {
      initialValues: {
        type: Object,
        default: () => ({})
      },
    },
    data(){
      return {
        footer: {
          primaryButton: 'ok',
          tertiaryButton: 'back'
        },
      }
    },
    computed: {
      identities() {
        return get(this.initialValues, 'identities', []);
      },
      preparedInitialValues() {
        const { id, firstName, lastName, emails = [], phones = [], identities = [] } = this.initialValues;
        // Person supports array of emails and phones. TODO: implement adding several emails, phones for a person
        const [{ email } = {}] = emails;
        const [{ phoneNumber: phone} = {}] = phones;

        return {
          id,
          firstName,
          lastName,
          email,
          phone,
          identities,
        };
      },
    },
    methods: {
      cancel(e) {
        e.preventDefault();
        this.close();
      },
    },
  }
</script>
<style scoped>

</style>
