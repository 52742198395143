<template>
  <Details
    title="person"
    ref="details"
    resource="persons"
    :base-path="basePath"
    route-id-param="personId"
    data-provider="$raaDataProvider"
    :title-template-render="renderTitle"
    :breadcrumb-template-render="renderBreadcrumbs"
  >
    <template v-slot:side-panel="{ record }">
      <DetailsSidePanel
        entity-title="person"
        :view-mode="record.source !== authSource || Boolean(record.deletedAt)"
        :source="record.source !== authSource ? record.source : ''">
      </DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <div class="flex flex-col items-end justify-end text-sm mb-4">
        <span>source: <span class="text-black font-600 ml-1">{{detailsProps.record.source}}</span></span>
        <span v-if="detailsProps.record.deletedAt">{{detailsProps.getDeletedLabel(detailsProps.record.deletedAt)}}</span>
      </div>
      <PersonForm
        v-if="detailsProps.record.source === authSource && !detailsProps.record.deletedAt"
        :initial-values="prepareInitialValues(detailsProps.record)"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
        @reinvite="resendInvitation"
      />
      <PersonDetails v-else :initial-values="prepareInitialValues(detailsProps.record)" />
    </template>
  </Details>
</template>

<script>
  import {get} from 'lodash-es';
  import ActionsMixin from '@/mixins/ActionsMixin';
  import NotifyMixin from '@/mixins/NotifyMixin';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import Details from '@/components/auth/details/Details';
  import DetailsSidePanel from '@/components/auth/details/DetailsSidePanel';
  import PersonForm from '@/views/auth/raa-devtools/persons/PersonForm';
  import PersonDetails from '@/views/auth/raa-devtools/persons/PersonDetails';
  import { AUTH_ALIAS } from '@/components/auth/constants';

  export default {
    name: 'PersonEdit',
    components: {PersonDetails, PersonForm, Details, DetailsSidePanel},
    mixins: [ModalNavigation, ActionsMixin, NotifyMixin],
    computed: {
      basePath: function () {
        return this.$route.name.replace('edit', 'index');
      },
      personId() {
        return this.$route.params.personId;
      },
      identityId() {
        return get(this.record, 'identities[0].id');
      },
      authSource() {
        return AUTH_ALIAS;
      },
    },
    data() {
      return {
        record: {},
      }
    },
    methods: {
      prepareInitialValues({ identities = [], id, firstName, lastName, emails = [], phones = [] }) {
        // Person supports array of emails and phones. TODO: implement adding several emails, phones for a person
        const [{ email } = {}] = emails;
        const [{ phoneNumber: phone} = {}] = phones;

        return {
          id,
          firstName,
          lastName,
          email,
          phone,
          identities,
        };
      },
      resendInvitation(identityId) {
        this.$router.push({
          name: this.$route.name.replace('edit', 'invite.index'),
          params: this.$route.params,
          query: {'identity': identityId}
        });
      },
      renderBreadcrumbs({ source, deletedAt }) {
        return source === this.authSource && !deletedAt ? 'edit' : 'view';
      },
      renderTitle({ source, deletedAt }) {
        return `${source === this.authSource && !deletedAt ? 'edit' : 'view'} person`;
      }
    },
    mounted(){
      this.$watch('$refs.details.record', function (record) {
        this.record = record;
        if (record && !record.deletedAt && record.identities?.length > 0) {
            this.addActionToStore({
              routeName: this.$route.name,
              item: {
                id: 'resend-person-invitation',
                title: 'resend invitation',
                routeName: 'raaTools.persons.invite.index',
                params: { personId: '{personId}' },
                dynamic: true,
              },
            });
        }
      })
    }
  }
</script>
