import { mapActions, mapGetters } from 'vuex';
import { slugify } from '@/utils/String';
import EventBus from '@/utils/EventBus';

export default {
    data() {
        return {
            addedActions: [],
        };
    },

    computed: {
        ...mapGetters({
            getActionsByRouteName: 'actions/getActionsByRouteName',
        }),
    },

    methods: {
        ...mapActions({
            addActionToStore: 'actions/addAction',
            removeActionFromStore: 'actions/removeAction',
        }),
        addConfirmAction(action, callback) {
            const eventName = action.id ? `confirm-${action.id}` : `confirm-${slugify(action.title)}`;
            this.addedActions.push({ action, callback, eventName });

            this.addActionToStore({
                routeName: this.$route.name,
                item: {
                    ...action,
                    dynamic: true,
                },
            });

            EventBus.on(eventName, callback);
        },
        removeConfirmAction(actionId) {
            if (!this.hasConfirmAction(actionId)) {
                return;
            }

            const { action, eventName, callback } = this.addedActions.find(({ action: { id } }) => id === actionId);

            EventBus.off(eventName, callback);

            this.removeActionFromStore({
                routeName: this.$route.name,
                item: action,
                title: action.title,
            });

            this.addedActions = this.addedActions.filter(({ action: { id } }) => id !== actionId);
        },
        hasConfirmAction(actionId) {
            return this.addedActions.findIndex(({ action: { id } }) => id === actionId) !== -1;
        },
    },

    mounted() {
        const routeActions = this.getActionsByRouteName(this.$route.name);
        const dynamicRouteActions = routeActions.filter(({ dynamic }) => dynamic);

        // get and remove all previously dynamically added actions
        dynamicRouteActions.forEach(action =>
            this.removeActionFromStore({
                routeName: this.$route.name,
                title: action.title,
            })
        );
    },

    beforeUnmount() {
        EventBus.emit('hide-modal-confirmation');

        this.addedActions.forEach(({ eventName, callback }) => EventBus.off(eventName, callback));
    },
};
