<template>
    <Form
        :submit="handleSubmit"
        :initialValues="initialValues"
        ref="form"
    >
        <div class="form-narrow">
            <div class="form-section-title">contact information</div>
            <div class="form-row">
                <TextField name="firstName" label="first name*" :validate="required"/>
                <TextField name="lastName" label="last name*" :validate="required"/>
            </div>
            <div class="form-row">
                <TextField name="email" label="email*" :validate="[required, email]"/>
                <PhoneInput name="phone" label="phone" :validate="usPhoneNumber"/>
            </div>

            <div class="form-section-title items-center mt-10" :style="{paddingBottom: '0.5rem'}">cognito identity</div>
            <div class="mb-6 text-2sm">
              Cognito identity can be found by email, phone or identity id, and linked to the contact. If no existing cognito identity is found then a new one can be created.
            </div>
            <PersonCognitoIdentities
                :initial-values="initPersonIdentities"
                @update="updateCognitoIdentities"
                @reinvite="(id) => $emit('reinvite', id)"
            />
            <ModalFooter :footer="footer" :tertiary="cancel" />
        </div>
    </Form>
</template>

<script>
  import { parsePhoneNumberFromString } from 'libphonenumber-js';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from '@/mixins/NotifyMixin';
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Form from '@/components/form/Form';
  import ModalFooter from '@/components/ui/modals/ModalFooter';
  import TextField from '@/components/form/TextField';
  import PhoneInput from '@/components/form/PhoneInput';
  import PersonCognitoIdentities from '@/views/auth/raa-devtools/persons/PersonCognitoIdentities';

  export default {
      name: 'PersonForm',
      components: {
          Form,
          ModalFooter,
          TextField,
          PhoneInput,
          PersonCognitoIdentities,
      },
      mixins: [
          ModalNavigation,
          NotifyMixin,
          ValidatorMixin,
      ],
      props: {
          onSubmit: {
              type: Function,
              required: true,
          },
          initialValues: {
              type: Object,
              default: () => ({})
          },
          loading: {
              type: Boolean,
              required: false,
              default: false,
          },
      },
    emits: ['reinvite'],
    data(){
          return {
              footer: {
                  primaryButton: 'save',
                  tertiaryButton: 'cancel'
              },
              identityIds: [],
          }
      },
      computed: {
          initPersonIdentities() {
              const { identities = [] } = this.initialValues;
              return identities.map(({ cognitoIdentityId, email, id: identityId, phone, active }) => ({
                cognitoIdentityId,
                email,
                identityId,
                phone: phone ?? '',
                active,
              }));
          },
      },
      methods: {
          handleSubmit({ email, phone, ...values }) {
              if (phone) {
                  const { countryCallingCode } = parsePhoneNumberFromString(phone);
                  values.phones = [
                      {
                          phoneNumber: phone,
                          countryCode: countryCallingCode,
                      },
                  ];
              }
              this.onSubmit({
                  ...values,
                  emails: [{ email }],
                  identityIds: this.identityIds,
              });
          },

          updateCognitoIdentities(identityIds) {
              this.identityIds = identityIds;
          },

          cancel(e) {
              e.preventDefault();
              this.close();
          },
      },
  }
</script>
